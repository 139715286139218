import { all } from 'redux-saga/effects';
import authSagas from '@iso/redux/auth/saga';
import profileSaga from '@iso/redux/profile/saga';
import normalUser from '@iso/redux/normalUser/sagas';
import projectCategory from '@iso/redux/projectCategory/sagas';
import productionHouseAdmin from '@iso/redux/productionHouseAdmin/sagas';
import transactionHistory from '@iso/redux/transactionHistory/sagas';
import subscriptionPlanManagement from '@iso/redux/subscriptionPlanManagement/sagas';
import reports from '@iso/redux/reports/sagas';

/* eslint-disable no-unused-vars */
export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    profileSaga(),
    normalUser(),
    projectCategory(),
    productionHouseAdmin(),
    transactionHistory(),
    subscriptionPlanManagement(),
    reports()
  ]);
}
